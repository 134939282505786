/* eslint-disable no-empty, camelcase, prefer-destructuring, jsx-a11y/label-has-for, react/no-array-index-key, max-len, no-shadow */
import React, { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { Cookie } from 'next-cookie';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';
import FormData from 'form-data';
import { useDispatch, useSelector } from 'react-redux';
import wrapper from '../../redux';
import { generateCourseEMIOrder, getUserEmiValidity, showEmiModal } from '../../redux/slices/librarySlice';
import usePayment from '../../hooks/usePayment';
import { startPaymentProcess, startPaytmPaymentProcess } from '../../redux/slices/cartSlice';
import { getCourseDetails, setChangeMode } from '../../redux/slices/courseDetailSlice';
import { showLogin, clearCRMData, validateAppAuth, validateLoginOtpCrmOrder } from '../../redux/slices/authSlice/actions';
import { changeSceneHistory, createRazorpayOrder, getCrmOrderInitiate, resetPaymentProcess, startBuyNowProcess } from '../../redux/slices/checkoutSlice';

import { CourseActionButton } from '../../components/Course/ActionButton';

import useSourceScreen from '../../hooks/useSourceScreen';
import { EVENT_ATTRIBUTE, EVENT_NAME, useMoengageEventTracker } from '../../hooks/useMoengage';
import useRazorpay from '../../hooks/useRazorpay';

import CCAvenuePaymentService from '../../service/payment/cc-avenue';
import * as PaymentActions from '../../utils/paymentConfig';
import { getAuthState, javaApi, phpApi } from '../../utils/AxiosInstance';
import { addCourseToLocalStorage, fetchCourseSlugData, objToCamelCase, pluralVal } from '../../utils/helper';
import { LanguageMapping } from '../../utils/AnswerWritingUtil';
import { CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY, FRONTEND_DOMAIN, RECENT_VISITED_PRODUCTS_STORAGE_KEY } from '../../constants/api';

import lectureIcon from '../../assets/images/course-detail/lecture.png';
import quizIcon from '../../assets/images/course-detail/quiz.png';
import updateIcon from '../../assets/images/course-detail/update.png';
import videoIcon from '../../assets/images/course-detail/video.png';
import ErrorIcon from '../../assets/images/course-detail/error_icon.svg';

import styles from './course-detail.module.scss';

const Authors = dynamic(() => import('../../components/Course/Authors'));
const CourseContent = dynamic(() => import('../../components/Course/CourseContent'));
const DemoVideos = dynamic(() => import('../../components/Course/DemoVideos'));
const ExamsCovered = dynamic(() => import('../../components/Course/ExamsCovered'));
const FAQsSection = dynamic(() => import('../../components/Course/FAQs'));
const CourseHeaderNavigation = dynamic(() => import('../../components/Course/Header/Navigation'));
const CoursePackages = dynamic(() => import('../../components/Course/Packages'));
const SimilarCourses = dynamic(() => import('../../components/Course/SimilarCourses'));
const CourseEMIModal = dynamic(() => import('../../components/MyLibrary/CourseCard/CourseEMIModal'));
const PaymentModal = dynamic(() => import('../../components/PaymentModal/PaymentModal'));

function CourseDetailPage({ courseDetailServer, retryOrder }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const sourceScreen = useSourceScreen();
  const trackMoengageEvent = useMoengageEventTracker();
  const { activePaymentMethods } = usePayment();

  const examsSection = React.useRef(null);
  const authorsSection = React.useRef(null);
  const courseContentSection = React.useRef(null);
  const cookies = new Cookie();
  const faqSection = React.useRef(null);
  const demoVideosSection = React.useRef(null);
  const priceSection = React.useRef(null);
  const courseDetailSection = React.useRef(null);
  const courseTimeStamp = React.useRef(Date.now());
  const [offlineError, setOfflineError] = useState(false);
  const [courseDetail, setCourseDetail] = useState(courseDetailServer);

  const [selectedPaymentGateway, setPaymentGateway] = React.useState('');
  const { authUser, courseDetailState, modeData, emiModal,
    paymentModal, selectedEMI, courseOrderNumber, courseAmount,
    paymentProcessStarted, isPayTmProcessStarted, paytmData, paytmUrl,
    paymentData, paymentProcessor, paymentProcess,
  } = useSelector((state) => ({
    authUser: state.auth.authUser,
    courseDetailState: state?.course?.courseDetail?.data,
    modeData: state?.course?.modeData,
    emiModal: state.library.emiModal?.show,
    paymentModal: state.library.paymentModal,
    selectedEMI: state.library.selectedEMI,
    courseOrderNumber: state.library.courseOrderNumber,
    courseAmount: state.library.courseAmount,
    paymentProcessStarted: state.cart.paymentProcessStarted,
    isPayTmProcessStarted: state.cart.isPayTmProcessStarted,
    paytmData: state.cart.paytmData,
    paytmUrl: state.cart.paytmUrl,
    paymentData: state.cart.paymentData,
    paymentProcessor: state.checkout.paymentProcess.processor,
    paymentProcess: state.checkout.paymentProcess.detail,
  }));

  useEffect(() => {
    if (courseDetailServer?.courseDetail) {
      const courseDetail = {
        title: courseDetailServer.courseDetail.course_title,
        courseId: parseInt(courseDetailServer.courseDetail.course_id, 10),
        discountPrice: courseDetailServer.courseDetail.package_discount_price_2,
        slug: courseDetailServer.slug,
        thumbnailUrl: courseDetailServer.courseDetail.thumbnail,
        basePrice: courseDetailServer.courseDetail.course_base_price,
        info: courseDetailServer.priceInfo,
      };

      let continuePurchaseProducts = window.localStorage.getItem(CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY) || '[]';
      try {
        continuePurchaseProducts = JSON.parse(continuePurchaseProducts);
      } catch {
        continuePurchaseProducts = [];
      }

      const courseIndex = continuePurchaseProducts
        ?.findIndex((continuePurchaseProduct) => continuePurchaseProduct.courseId === courseDetail.courseId);
      if (courseIndex === -1) {
        addCourseToLocalStorage(courseDetail, RECENT_VISITED_PRODUCTS_STORAGE_KEY);
      }

      trackMoengageEvent(EVENT_NAME.RECENTLY_VIEWED, courseDetail);
    }
  }, [courseDetailServer]);

  useEffect(() => {
    if (courseDetailState?.courseDetail?.course_id && courseDetailServer?.courseDetail?.course_id) {
      setCourseDetail(courseDetailState);
    } else {
      setCourseDetail(courseDetailServer);
    }
  }, [courseDetailState?.courseDetail?.course_id, courseDetailServer?.courseDetail?.course_id]);

  const getSlug = () => courseDetailServer?.slug;

  // Extract Course Detail
  const {
    courseId,
    isCourseFree,
    courseTitle,
    courseDescription,
    courseDescriptionHtml,
    courseThumbnail,
    metaTitle,
    metaKeyword,
    metaDescription,
    courseTypeId,
    coursePlanPdf,
    courseDefaultLanguage,
    courseSalePrice,
    courseNetPrice,
    courseDiscountText,
  } = React.useMemo(
    () => ({
      courseId: parseInt(courseDetail?.courseDetail?.course_id, 10),
      isCourseFree: Boolean(courseDetail?.courseDetail?.is_course_free),
      courseTitle: courseDetail?.courseDetail?.course_title,
      courseDescription: courseDetail?.courseDetail?.course_description,
      courseDescriptionHtml: courseDetail?.courseDetail?.course_description_html,
      courseThumbnail: courseDetail?.courseDetail?.thumbnail,
      metaTitle: courseDetail?.courseDetail?.meta_title,
      metaKeyword: courseDetail?.courseDetail?.meta_keyword,
      metaDescription: courseDetail?.courseDetail?.meta_description,
      courseTypeId: courseDetail?.courseDetail?.course_type_id,
      coursePlanPdf: courseDetail?.courseDetail?.course_plan_pdf,
      courseDefaultLanguage: parseInt(courseDetail?.courseDetail?.default_language_id, 10),
      courseSalePrice: courseDetail?.courseDetail?.package_discount_price_2,
      courseNetPrice: courseDetail?.courseDetail?.course_base_price,
      courseDiscountText: courseDetail?.courseDetail?.discount_text,
    }),
    [courseDetail?.courseDetail],
  );

  // Extract Course Info
  const { courseVideosCount, courseQuizCount, courseLectureCount, courseUpdatesCount } = React.useMemo(
    () => ({
      courseVideosCount: parseInt(courseDetail?.courseInfo?.no_of_videos, 10),
      courseQuizCount: parseInt(courseDetail?.courseInfo?.no_of_quiz, 10),
      courseLectureCount: parseInt(courseDetail?.courseInfo?.lecture, 10),
      courseUpdatesCount: parseInt(courseDetail?.courseInfo?.updates, 10),
    }),
    [courseDetail?.courseInfo],
  );

  // Extract Course User Info
  const { isPurchased, validityDaysLeft, userCourseLanguage } = React.useMemo(
    () => ({
      isPurchased: Boolean(courseDetail?.userDetails?.purchased),
      validityDaysLeft: parseInt(courseDetail?.userDetails?.validityLeftDays, 10),
      userCourseLanguage: parseInt(courseDetail?.userDetails?.orderLanguageId, 10),
    }),
    [courseDetail?.userDetails],
  );

  const [isRazorpayReady, razorpay] = useRazorpay({
    prefill: {
      name: authUser?.firstName,
      email: authUser?.email,
      contact: authUser?.mobileNumber,
    },
    notes: {
      orderId: courseId,
    },
    theme: {
      color: '#423dcf',
    },
    modal: {
      ondismiss() {
        setPaymentGateway('');
        dispatch(showEmiModal({ show: false }));
        dispatch(resetPaymentProcess());
      },
    },
  });

  // const { trackMoengageEvent } = useMoengageEventTracker({ course_id: courseId });

  const [headerSticky, setHeaderSticky] = React.useState(false);
  const [courseLanguage, setCourseLanguage] = React.useState(() => userCourseLanguage || courseDefaultLanguage);
  const [coursePackages, setCoursePackages] = React.useState([]);
  const [haveExpandedDescription, setHaveExpandedDescription] = React.useState(false);
  const [currentSection, setCurrentSection] = React.useState(null);
  const [trackerCrossSell, setTrackerCrossSell] = useState({});
  const [crmFlag, setCrmFlag] = useState(false);

  const courseInfoItems = React.useMemo(
    () => [
      {
        key: 'videos',
        title: 'Videos',
        icon: videoIcon,
        isEnabled: courseVideosCount > 0,
        count: courseVideosCount,
      },
      {
        key: 'quiz',
        title: 'Quiz',
        icon: quizIcon,
        isEnabled: courseQuizCount > 0,
        count: courseQuizCount,
      },
      {
        key: 'notes',
        title: 'Lecture Notes',
        icon: lectureIcon,
        isEnabled: courseLectureCount > 0,
        count: courseLectureCount,
      },
      {
        key: 'updates',
        title: 'Updates',
        icon: updateIcon,
        isEnabled: courseUpdatesCount > 0,
        count: courseUpdatesCount,
      },
    ],
    [courseQuizCount, courseVideosCount, courseUpdatesCount, courseLectureCount],
  );

  const discountPercentage = React.useMemo(
    () => (((courseNetPrice - courseSalePrice) * 100) / courseNetPrice).toFixed(0),
    [courseNetPrice, courseSalePrice],
  );
  const haveCourseInfo = Boolean(courseVideosCount || courseQuizCount || courseLectureCount || courseUpdatesCount);

  const similarCourses = courseDetail?.crossSellDetails || [];
  const courseAuthors = courseDetail?.authors ?? [];
  const examsCovered = courseDetail?.examCovered ?? [];
  const demoVideos = courseDetail?.demoUrls ?? [];
  const courseFaqs = courseDetail?.courseFaqs ?? [];
  const courseFeatures = courseDetail?.features ?? [];
  const bundleCourses = objToCamelCase(courseDetail?.bundlecourses ?? []);

  React.useEffect(() => {
    setCourseLanguage(userCourseLanguage || courseDefaultLanguage);
  }, [userCourseLanguage, courseDefaultLanguage]);

  React.useEffect(() => {
    if (courseId && courseLanguage) {
      const payload = {
        course_id: courseId,
        language_id: courseLanguage,
      };
      if (modeData?.mode === 'offline' && modeData?.location) {
        payload.offlineCentreId = parseInt(modeData?.location, 10);
      }
      phpApi
        .get('course_language_packages', {
          params: payload,
        })
        .then((response) => {
          setCoursePackages(response.data.data ?? []);
        });
    }
  }, [courseId, courseLanguage, modeData?.location]);

  React.useEffect(() => {
    setTrackerCrossSell({
      cross_sell_id: courseDetail?.courseDetail?.course_id,
      cross_sell_name: courseDetail?.courseDetail?.course_title,
      deeplink_source: window.location.href,
      screen_source: 'cross_sell_pdp',
    });
    try {
      let moeObj = {
        [EVENT_ATTRIBUTE.COURSE_ID]: courseDetail?.courseDetail?.course_id,
        [EVENT_ATTRIBUTE.COURSE_NAME]: courseDetail?.courseDetail?.course_title,
        [EVENT_ATTRIBUTE.COURSE_TYPE]: courseDetail?.courseDetail?.course_type,
        [EVENT_ATTRIBUTE.SOURCE_SCREEN]: sourceScreen || 'deeplink',
      };
      if (window.crossSellTracker?.tracker) {
        sessionStorage?.setItem('CROSS_SELL_PREV', JSON.stringify(window.crossSellTracker?.tracker));
        moeObj = {
          ...moeObj,
          [EVENT_ATTRIBUTE.CROSS_SELL_ID]: window.crossSellTracker?.tracker?.cross_sell_id,
          [EVENT_ATTRIBUTE.CROSS_SELL_NAME]: window.crossSellTracker?.tracker?.cross_sell_name,
          [EVENT_ATTRIBUTE.DEEPLINK_SOURCE]: window.crossSellTracker?.tracker?.deeplink_source,
          [EVENT_ATTRIBUTE.SOURCE_SCREEN]: window.crossSellTracker?.tracker?.screen_source,
        };
      } else {
        sessionStorage.setItem('CROSS_SELL_PREV', '');
      }
      trackMoengageEvent(EVENT_NAME.PDP_VISITED, moeObj);
    } catch (error) {
      // Handle error
    }
  }, []);

  const trackBuyNowEvent = React.useCallback((action) => {
    try {
      trackMoengageEvent(
        EVENT_NAME.BUY_NOW_CLICKED,
        {
          [EVENT_ATTRIBUTE.COURSE_CATEGORY]: courseDetail?.courseDetail?.course_type,
          [EVENT_ATTRIBUTE.COURSE_ID]: courseDetail?.courseDetail?.course_id,
          [EVENT_ATTRIBUTE.COURSE_NAME]: courseDetail?.courseDetail?.course_title,
          [EVENT_ATTRIBUTE.COURSE_TYPE]: courseDetail?.courseDetail?.course_type,
          [EVENT_ATTRIBUTE.SOURCE_SCREEN]: sourceScreen,
          action,
        },
      );
      trackMoengageEvent(EVENT_NAME.PDP_ENGAGEMENT, {
        [EVENT_ATTRIBUTE.PDP_TIME_SPENT]: Math.round((Date.now() - courseTimeStamp.current) / 1000),
        [EVENT_ATTRIBUTE.SCREEN_NAME]: 'buy_now',
        [EVENT_ATTRIBUTE.COURSE_ID]: courseDetail?.courseDetail?.course_id,
      });
    } catch (error) {
      // Handle error
    }
  }, [
    courseDetail?.courseType?.courseTypeName,
    courseTitle, isCourseFree, sourceScreen, trackMoengageEvent,
  ]);

  React.useEffect(() => {
    if (
      isRazorpayReady &&
      paymentProcess &&
      paymentProcessor === 'RAZORPAY' &&
      selectedPaymentGateway === activePaymentMethods.RAZORPAY
    ) {
      const razorpayInstance = razorpay({
        key: paymentProcess.razorPayApiKey,
        amount: paymentProcess.amount,
        currency: paymentProcess.currency,
        order_id: paymentProcess.id,
        handler() {
          window.location.href = `/payment-response?order_id=${paymentProcess.orderId}`;
        },
      });

      razorpayInstance.startTransaction();
    }
  }, [isRazorpayReady, paymentProcess, paymentProcessor, selectedPaymentGateway]);

  React.useEffect(() => {
    if (
      isRazorpayReady &&
      courseOrderNumber &&
      courseAmount &&
      selectedPaymentGateway === activePaymentMethods.RAZORPAY
    ) {
      dispatch(
        createRazorpayOrder({
          orderId: courseOrderNumber,
          orderPrice: courseAmount,
        }),
      );
    }
  }, [dispatch, isRazorpayReady, courseAmount, courseOrderNumber, selectedPaymentGateway, activePaymentMethods]);

  React.useEffect(() => {
    if (
      paymentProcessStarted &&
      paymentData &&
      selectedPaymentGateway === activePaymentMethods.CCAVENUE
    ) {
      CCAvenuePaymentService.startTransaction('OPTNBK', paymentData);
    }
  }, [paymentProcessStarted, paymentData, selectedPaymentGateway]);

  React.useEffect(() => {
    if (
      isPayTmProcessStarted &&
      paytmData &&
      selectedPaymentGateway === activePaymentMethods.PAYTM
    ) {
      PaymentActions.initiatePaytmTransaction(paytmData, paytmUrl);
    }
  }, [isPayTmProcessStarted, paytmData, paytmUrl, selectedPaymentGateway]);

  React.useEffect(() => {
    const userID = cookies.get('userID');
    if (courseOrderNumber && courseAmount && selectedPaymentGateway) {
      const action = 1;
      if (selectedPaymentGateway === activePaymentMethods.CCAVENUE) {
        dispatch(
          startPaymentProcess({
            orderID: courseOrderNumber,
            orderPrice: courseAmount,
            action,
          }),
        );
      }
      if (selectedPaymentGateway === activePaymentMethods.PAYTM && userID) {
        dispatch(startPaytmPaymentProcess({ orderID: courseOrderNumber, action }));
      }
    }
  }, [courseOrderNumber, courseAmount, dispatch]);

  React.useEffect(() => {
    const userID = cookies.get('userID');
    const courseId = courseDetail?.courseDetail?.course_id;
    const lanugageId = courseDetail?.userDetails?.orderLanguageId;
    const paymentAction = selectedEMI?.offer_status === 1 ? 1 : 0;

    if (userID && courseDetail?.courseDetail?.course_id && selectedPaymentGateway) {
      dispatch(
        generateCourseEMIOrder({
          courseID: courseId,
          languageID: lanugageId,
          emiID: selectedEMI?.orderCourseEmiId,
          amount: selectedEMI?.emiAmount,
          action: paymentAction,
          selectedPaymentStream: selectedPaymentGateway,
        }),
      );
    }
  }, [selectedPaymentGateway]);

  const handleEmiRepayment = async () => {
    const courseId = courseDetail?.courseDetail?.course_id;
    const lanugageId = courseDetail?.userDetails?.orderLanguageId;
    dispatch(getUserEmiValidity({ courseId, lanugageId }));
    dispatch(showEmiModal({ show: true, courseId }));
  };

  const goToMyCourse = async () => {
    const course_detail = courseDetail?.courseDetail;
    const isFreeCourse = course_detail?.is_course_free === 1;
    const courseID = course_detail?.course_id;
    const languageID =
      courseDetail?.userDetails?.orderLanguageId ||
      courseDetail?.courseDetail?.default_language_id;

    let newId = null;
    let newSlug = null;
    const courseSlug = getSlug();
    let course_status = 'fully_paid';
    if (!courseDetail.free_course) {
      if (courseDetail.payment_type === 1) {
        course_status = 'renew';
      } else if (courseDetail.payment_type === 2) {
        course_status = 'EMI';
      }
    }
    try {
      trackMoengageEvent(EVENT_NAME.START_MY_COURSE, {
        [EVENT_ATTRIBUTE.COURSE_ID]: courseID,
        [EVENT_ATTRIBUTE.COURSE_NAME]: course_detail?.course_title,
        [EVENT_ATTRIBUTE.COURSE_TYPE]: course_detail?.course_type_id,
        [EVENT_ATTRIBUTE.COURSE_STATUS]: course_detail?.is_course_free ? 'free' : course_status,
        [EVENT_ATTRIBUTE.COURSE_CATEGORY]: course_detail?.course_type,
        [EVENT_ATTRIBUTE.EXPIRES_IN]: courseDetail?.userDetails?.validityLeftDays >= 0 ? courseDetail?.userDetails?.validityLeftDays : 'free',
        is_live: 'pdp',
      });
    } catch (error) {
      console.log(error);
    }
    try {
      const { id, slug } = await fetchCourseSlugData(courseID, languageID);
      newId = id;
      newSlug = slug;
    } catch (error) {
    } finally {
      if (isFreeCourse) {
        if (courseID && languageID && courseSlug) {
          let newWebConsumeLink;
          if (newId && newSlug) {
            newWebConsumeLink = `/course-content/${courseID}-${languageID}/${newId}/${newSlug}`;
          } else {
            newWebConsumeLink = `/course-content/${courseID}-${languageID}/${courseSlug}`;
          }
          router.push(newWebConsumeLink);
        } else {
          router.push(`/free-course/${courseSlug}`);
        }
      } else {
        let newWebConsumeLink = '';
        if (courseDetail?.userDetails?.todaysScheduledLiveClass) {
          newWebConsumeLink = `/course-content/${courseID}-${languageID}/live-class`;
        } else if (newId && newSlug) {
          newWebConsumeLink = `/course-content/${courseID}-${languageID}/${newId}/${newSlug}`;
        } else {
          newWebConsumeLink = `/course-content/${courseID}-${languageID}/${courseSlug}`;
        }
        router.push(newWebConsumeLink);
      }
    }
  };

  const handleBuyNow = (action) => {
    if (modeData?.mode === 'offline' && !modeData?.location) {
      setOfflineError(true);
      const errorElem = document.getElementById('location_error_id');
      if (errorElem) {
        errorElem?.scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
    }

    if (action === 3) {
      if (!authUser) {
        dispatch(showLogin());

        return;
      }

      toast.custom((toastId) => (
        <div id={toastId} className={styles.emiAvailableWrapper}>
          <button type="button" className={styles.closeBtn} onClick={() => toast.dismiss(toastId)}>
            <FontAwesomeIcon icon={faTimes} size="6x" />
          </button>

          <div className={styles.emiAvailableContent}>
            <Image
              src="/images/emi-request.png"
              alt="EMI Request"
              width={134}
              height={90}
              className={styles.image}
            />

            <div className={styles.content}>
              <h6>Your EMI Request is Confirmed!</h6>
              {/* eslint-disable-next-line max-len */}
              <p> A counsellor will reach out to you within the next 24 hours to guide you through the process.</p>
            </div>
          </div>
        </div>
      ), { duration: 100000, dismissible: true });

      trackMoengageEvent('avail_with_emi_clicked', {
        [EVENT_ATTRIBUTE.COURSE_ID]: courseDetail?.courseDetail?.course_id,
        [EVENT_ATTRIBUTE.COURSE_NAME]: courseDetail?.courseDetail?.course_title,
        [EVENT_ATTRIBUTE.COURSE_TYPE]: courseDetail?.courseDetail?.course_type,
        [EVENT_ATTRIBUTE.IS_COURSE_FREE]: courseDetail?.courseDetail?.is_course_free,
        [EVENT_ATTRIBUTE.SOURCE_SCREEN]: sourceScreen || 'deeplink',
        [EVENT_ATTRIBUTE.LANGUAGE_ID]: courseDetail?.courseDetail?.default_language_id,
        [EVENT_ATTRIBUTE.COURSE_SELLINGPRICE]: courseDetail?.courseDetail?.package_discount_price_2
          || courseDetail?.courseDetail?.package_discount_price_1,
        [EVENT_ATTRIBUTE.COURSE_CATEGORY]: courseDetail?.courseType?.courseTypeName,
        [EVENT_ATTRIBUTE.LANGUAGE_SELECTED]: LanguageMapping(courseDetail?.courseDetail?.default_language_id),
      });

      return;
    }

    trackBuyNowEvent(action === 2 ? 'Renew' : 'Fresh');

    // Handle Buy Now Flow
    dispatch(
      startBuyNowProcess({
        courseId,
        languageId: courseLanguage,
        checkoutAction: action,
      }),
    );
  };

  const getCRMdata = async () => {
    const queryArr = window.location.search?.slice(1)?.split('&');
    const queryArrId = queryArr?.filter(
      (elem) => elem?.split('=')[0] === 'order_initiate_id',
    );
    const orderInitiateId = queryArrId[0]?.split('=')?.slice(-1)[0];
    const queryArrKey = queryArr?.filter((elem) => elem?.split('=')[0] === 'key');
    const userKey = queryArrKey[0]?.split('=')?.slice(-1)[0];
    const crmRes = await dispatch(getCrmOrderInitiate({ orderInitiateId, userKey }));
    const data = crmRes?.payload;
    const userIDKey = cookies.get('userID');

    if (data?.user_detail?.user_id) {
      if ((userIDKey?.toString() !== data?.user_detail?.user_id?.toString()) && userIDKey) {
        dispatch(clearCRMData());
      } else if (data?.user_detail?.user_id) {
        const crmLink = window.location.search;
        const res = await dispatch(
          validateLoginOtpCrmOrder(
            {
              phoneNumber: data?.user_detail?.mobile_number,
              otp: data?.user_detail?.otp,
              userID: data?.user_detail?.user_id,
              orderData: data,
              orderInitiateId: parseInt(orderInitiateId, 10),
              crmLink,
            },
          ),
        );
        const resData = res?.payload;
        if (resData?.data?.user_id) {
          setCrmFlag(!crmFlag);
          handleBuyNow(data?.course_package_detail?.action);
        }
      }
    } else {
      dispatch(clearCRMData());
    }
  };

  React.useEffect(() => {
    const queryArr = window.location.search
      ?.slice(1)
      ?.replace(/&/g, '=')
      ?.split('=');
    if (
      queryArr.length > 0 &&
      queryArr.includes('order_initiate_id') &&
      queryArr.includes('key')
    ) {
      getCRMdata();
    } else {
      dispatch(clearCRMData());
    }
  }, []);

  // Course Nav active section Highlighter
  const handleScroll = React.useCallback(() => {
    const windowHeight = window.innerHeight / 2;
    const aboutAuthorsSectionOffset = authorsSection.current?.offsetTop;
    const faqsSectionOffset = faqSection.current?.offsetTop;
    const priceSectionOffset = priceSection.current?.offsetTop;
    const demoVideosSectionOffset = demoVideosSection.current?.offsetTop;
    const courseContentSectionOffset = courseContentSection.current?.offsetTop;
    const examsSectionOffset = examsSection.current?.offsetTop;
    const courseDetailOffset = courseDetailSection.current?.offsetTop;

    // Set Header Sticky
    setHeaderSticky(window.scrollY > 345);

    if (window.scrollY >= faqsSectionOffset - windowHeight) {
      setCurrentSection('faqs');
    } else if (window.scrollY >= priceSectionOffset - windowHeight) {
      setCurrentSection('price');
    } else if (window.scrollY >= courseContentSectionOffset - windowHeight) {
      setCurrentSection('course-content');
    } else if (window.scrollY >= demoVideosSectionOffset - windowHeight) {
      setCurrentSection('demo-videos');
    } else if (window.scrollY >= aboutAuthorsSectionOffset - windowHeight) {
      setCurrentSection('about-authors');
    } else if (window.scrollY >= examsSectionOffset - windowHeight) {
      setCurrentSection('exams-covered');
    } else if (window.scrollY >= courseDetailOffset - windowHeight) {
      setCurrentSection('course-details');
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleOnItemClick = (sectionId) => (event) => {
    event.preventDefault();

    let currentSectionRef = null;

    switch (sectionId) {
      case 'course-details':
        currentSectionRef = courseDetailSection;
        break;
      case 'course-plan-pdf':
        window.open(coursePlanPdf);
        return;
      case 'exams-covered':
        currentSectionRef = examsSection;
        break;
      case 'about-authors':
        currentSectionRef = authorsSection;
        break;
      case 'demo-videos':
        currentSectionRef = demoVideosSection;
        break;
      case 'course-content':
        currentSectionRef = courseContentSection;
        break;
      case 'pricing':
        dispatch(
          startBuyNowProcess({
            courseId,
            languageId: courseLanguage,
            checkoutAction: isPurchased && validityDaysLeft > -1 ? 2 : 1,
          }),
        );
        break;
      case 'faqs':
        {
          currentSectionRef = faqSection;
          try {
            trackMoengageEvent(EVENT_NAME.FAQS_CLICKED, {
              [EVENT_ATTRIBUTE.COURSE_ID]: courseDetail?.courseDetail?.course_id,
              [EVENT_ATTRIBUTE.COURSE_NAME]: courseDetail?.courseDetail?.course_title,
              [EVENT_ATTRIBUTE.COURSE_TYPE]: courseDetail?.courseDetail?.course_type,
              [EVENT_ATTRIBUTE.IS_COURSE_FREE]: courseDetail?.courseDetail?.is_course_free,
              [EVENT_ATTRIBUTE.SOURCE_SCREEN]: sourceScreen || 'deeplink',
              [EVENT_ATTRIBUTE.LANGUAGE_ID]: courseDetail?.courseDetail?.default_language_id,
              [EVENT_ATTRIBUTE.COURSE_SELLINGPRICE]: courseDetail?.courseDetail?.package_discount_price_2
                || courseDetail?.courseDetail?.package_discount_price_1,
              [EVENT_ATTRIBUTE.LANGUAGE_SELECTED]: LanguageMapping(courseDetail?.courseDetail?.default_language_id),
            });
          } catch (error) {
            // Handle Error
          }
          break;
        }

      default:
        currentSectionRef = null;
    }

    if (
      currentSectionRef &&
      currentSectionRef.current &&
      typeof currentSectionRef.current.scrollIntoView === 'function'
    ) {
      currentSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      console.error('Ref is not correctly assigned or not available for:', sectionId);
    }
  };

  React.useEffect(() => {
    const isEMI = retryOrder?.orderDetails?.orderId?.split('_')?.includes('EMI');
    if (isEMI) {
      handleEmiRepayment();
      return;
    }
    if (retryOrder) {
      const { courseId, languageId, packageId, orderInitiateId } = retryOrder.orderHistory;
      const {
        orderType: checkoutAction, emiEnable: emiEnabled,
        couponCode, courseTypeId, addOnBookLanguageId,
        bookLanguageId,
      } = retryOrder.orderDetails;

      const buyNowHistory = ['PACKAGE'];

      if (courseDetail.bookLanguages.length) {
        buyNowHistory.push('BOOK_PACKAGE');
      }

      buyNowHistory.push('PRE_CHECKOUT');

      if (addOnBookLanguageId) {
        buyNowHistory.push('ADDRESS');
      }

      dispatch(changeSceneHistory(buyNowHistory));

      dispatch(
        startBuyNowProcess({
          courseId,
          packageId,
          languageId,
          emiEnabled,
          couponCode,
          courseTypeId,
          bookLanguageId,
          checkoutAction,
          orderInitiateId,
          addOnBookLanguageId,
        }),
      );
    }
  }, [dispatch, retryOrder, courseDetail?.bookLanguages?.length]);

  const handleCourseLanguageChange = (event) => {
    const selectedLanguageId = parseInt(event.target.value, 10);
    const selectedLanguage = courseDetail?.courseLanguages?.find((courseLanguage) => courseLanguage.language_id === selectedLanguageId);

    const { userID, authToken } = getAuthState();

    if (selectedLanguage) {
      trackMoengageEvent(EVENT_NAME.COURSELANGUAGE_SELECTED, {
        [EVENT_ATTRIBUTE.USER_TYPE]: userID && authToken ? 'registered' : 'guest',
        [EVENT_ATTRIBUTE.COURSE_NAME]: courseTitle,
        [EVENT_ATTRIBUTE.COURSE_TYPE]: isCourseFree ? 'free' : 'paid',
        [EVENT_ATTRIBUTE.COURSE_CATEGORY]: courseDetail?.courseType?.courseTypeName,
        [EVENT_ATTRIBUTE.COURSE_PURCHASED]: isPurchased ? 'yes' : 'no',
        [EVENT_ATTRIBUTE.LANGUAGE_SELECTED]: selectedLanguage.languageName,
      });

      setCourseLanguage(selectedLanguageId);
      dispatch(
        getCourseDetails(
          {
            courseNameSlug: getSlug(),
            selectedCourseLangage: selectedLanguageId,
            modeData,
          },
        ),
      );
    }
  };

  function getModeFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('mode');
  }

  const changeModeData = (data, key, type) => {
    let modeObj = {
      ...modeData,
      [key]: data,
    };
    if (type === 'all') {
      modeObj = data;
    }
    if (key === 'mode' || key === 'location') {
      // no need it here in new web reset states
    }
    if (data === 'online') {
      modeObj.location = '';
      setOfflineError(false);
    }
    if (data === 'offline') {
      if (courseDetail?.offlineCentres?.length === 1) {
        modeObj = {
          mode: 'offline',
          location: courseDetail?.offlineCentres?.[0]?.offline_centre_id,
        };
        dispatch(
          getCourseDetails({
            courseNameSlug: getSlug(),
            selectedCourseLangage: 0,
            modeData: modeObj,
          }),
        );
      }
    }
    if (data === 'online' && modeData?.mode === 'offline') {
      dispatch(
        getCourseDetails(
          {
            courseNameSlug: getSlug(),
            selectedCourseLangage: 0,
            modeData: modeObj,
          },
        ),
      );
    }
    dispatch(setChangeMode(modeObj));
    if (key === 'location') {
      dispatch(
        getCourseDetails(
          {
            courseNameSlug: getSlug(),
            selectedCourseLangage: 0,
            modeData: modeObj,
          },
        ),
      );
    }
  };

  React.useEffect(() => {
    const modesArray = [];
    if (courseDetail?.courseDetail?.course_id && !modeData) {
      if (courseDetail?.isOnlineEnabled) {
        modesArray.push({ name: 'online' });
      }
      if (courseDetail?.isOfflineEnabled && courseDetail?.offlineCentres?.length > 0) {
        modesArray.push({ name: 'offline' });
      }
      const mode = getModeFromUrl();
      if (mode === 'offline') {
        changeModeData('offline', 'mode');
      } else if (modesArray?.length > 0) {
        changeModeData(modesArray?.[0]?.name, 'mode');
      }
      if (courseDetail?.isOnlineEnabled === 0 && courseDetail?.isOfflineEnabled === 1) {
        changeModeData({
          mode: 'offline',
          location: courseDetail?.offlineCentres?.[0]?.offline_centre_id,
        }, 'location', 'all');
      }
    }
  }, [courseDetail?.courseDetail?.course_id]);

  const offlineErrorFlag = () => modeData?.location?.length === 0 && modeData?.mode === 'offline' && offlineError;

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: courseFaqs?.map((faq) => ({
      '@type': 'Question',
      name: faq.faq_question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.faq_answer,
      },
    })),
  };

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeyword} />

        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={courseThumbnail} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Study IQ Education" />
        <meta property="og:url" content={`${FRONTEND_DOMAIN}/course-detail/${courseDetail.slug}`} />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={courseThumbnail} />
        <meta name="twitter:image:alt" content="Study IQ" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@studyiq" />

        <link rel="canonical" href={`${FRONTEND_DOMAIN}/course-detail/${courseDetail.slug}`} />
        <meta name="robots" content="index, follow" />
      </Head>

      {courseFaqs?.length > 0 && (
        <Script
          id="faq-schema"
          type="application/ld+json"
        >
          {JSON.stringify(faqSchema)}
        </Script>
      )}

      <main className={styles.wrapper} data-testid="course-detail-main">
        <header className={styles.header} data-testid="course-header">
          <div className={styles.headerContent}>
            <h1 className={styles.title} data-testid="course-title">{courseTitle}</h1>
          </div>

          <CourseHeaderNavigation
            userDetails={courseDetail?.userDetails}
            currentSection={currentSection}
            isFreeCourse={isCourseFree}
            haveValidity={validityDaysLeft >= 0}
            courseTypeId={courseTypeId}
            haveDemoVideos={demoVideos.length > 0}
            haveExams={examsCovered.length > 0}
            haveFaqs={courseFaqs.length > 0}
            havePdf={Boolean(coursePlanPdf)}
            onItemClick={handleOnItemClick}
            handleBuyNow={(action) => handleBuyNow(action)}
            isSticky={headerSticky}
            goToMyCourse={goToMyCourse}
            handleEmiRepayment={handleEmiRepayment}
          />
        </header>

        <section className={styles.courseDetailCard} data-testid="course-detail-card">
          <div className={styles.thumbnailOutterWrapper}>
            <div className={styles.thumbnailWrapper}>
              <Image src={courseThumbnail} alt={courseTitle} fill />
            </div>
            {(isPurchased === false && courseDetail?.courseLanguages?.length) > 1 && (
              <lable className={styles.courseLanguageWrapper}>
                <span className={styles.courseLanguageLabel}>Select Course Language:</span>
                <select
                  className={styles.courseLanguageField}
                  defaultValue={courseDefaultLanguage}
                  onChange={handleCourseLanguageChange}
                  aria-label="Select Course Language"
                >
                  {courseDetail?.courseLanguages?.map((courseLanguage) => (
                    <option key={`course-language-${courseLanguage.language_id}`} value={courseLanguage.language_id}>
                      {courseLanguage.language_name}
                    </option>
                  ))}
                </select>
              </lable>
            )}
          </div>

          <div className={classNames(styles.coursePricingWrapper, { [styles.withCourseInfo]: haveCourseInfo })}>
            {isCourseFree === false && isPurchased === false && (
              <div className={styles.priceAndDiscountWrapper}>
                <div className={styles.priceWrapper}>
                  <span className={styles.salePrice}>₹{courseSalePrice}</span>
                  <span className={styles.netPrice}>₹{courseNetPrice}</span>
                </div>

                {discountPercentage && (
                  <span className={styles.courseDiscount}>
                    {discountPercentage}% Off - {courseDiscountText}
                  </span>
                )}
              </div>
            )}

            <div className={styles.actionWrapper}>
              <CourseActionButton
                userDetails={courseDetail?.userDetails}
                onClick={(action) => { handleBuyNow(action); }}
                goToMyCourse={goToMyCourse}
                handleEmiRepayment={handleEmiRepayment}
                withEmiButton={courseSalePrice >= 20000}
              />

              {courseDetail?.priceInfo?.length && <span className={styles.priceInfo}>* {courseDetail?.priceInfo}</span>}
            </div>
          </div>

          {haveCourseInfo && (
            <div className={styles.courseInfoWrapper}>
              <h3 className={styles.courseInfoTitle}>Courses Info</h3>

              <ul className={styles.courseInfoList}>
                {courseInfoItems.map(
                  (courseInfoItem) =>
                    courseInfoItem.isEnabled && (
                      <li key={courseInfoItem.key}>
                        <div className={classNames(styles.courseInfo, styles[courseInfoItem.key])}>
                          <Image
                            src={courseInfoItem.icon}
                            height={50}
                            width={50}
                            className={styles.courseInfoIcon}
                            alt={courseInfoItem.title}
                          />
                          <span className={styles.infoTitle}>
                            {courseInfoItem.title} ({courseInfoItem.count})
                          </span>
                        </div>
                      </li>
                    ),
                )}
              </ul>
            </div>
          )}
        </section>

        <span id={'location_error_id'} />
        {(!courseDetail?.userDetails?.purchased) &&
          <div className={styles.modes_of_learning}>
            <div className={styles.modes_select}>
              <p className={styles.modes_title}>Mode of Learning:
                <span className={styles.modes_title_modeName}>
                  {(courseDetail?.isOnlineEnabled === 1 && courseDetail?.isOfflineEnabled === 0) ? 'Online' : ''}
                  {(courseDetail?.isOnlineEnabled === 0 && courseDetail?.isOfflineEnabled === 1) ? 'Offline' : ''}
                </span>
              </p>
              {(courseDetail?.isOfflineEnabled === 1 && courseDetail?.isOnlineEnabled === 1) &&
                (
                  <div className={styles.inputs_wrapper}>
                    <div className={styles.modesLearning}>
                      {courseDetail?.isOnlineEnabled === 1 && <span className={modeData?.mode === 'online' ? styles.each_btn_mode_active : styles.each_btn_mode} onClick={() => changeModeData('online', 'mode')}>Online</span>}
                      {courseDetail?.offlineCentres?.length > 0 && courseDetail?.isOfflineEnabled === 1 && <span className={modeData?.mode === 'offline' ? styles.each_btn_mode_active : styles.each_btn_mode} onClick={() => changeModeData('offline', 'mode')}>Offline</span>}
                    </div>
                  </div>
                )}
            </div>

            {(modeData?.mode === 'offline' && courseDetail?.offlineCentres?.length > 0) && (
              <div className={`${styles.modes_select} ${styles.center_select_wrapper} ${offlineErrorFlag() ? styles.center_select_wrapper_error : ''}`}>
                <p className={`${styles.modes_title_center} ${offlineErrorFlag() ? styles.modes_title_center_error : ''}`}><span className={styles.select_title}>Select</span> Centre
                  <div className={`${styles.tooltip_triangle} ${offlineErrorFlag() ? styles.tooltip_triangle_error : ''}`} />
                </p>
                <div className={styles.inputs_wrapper_center}>
                  <div className={styles.radio_group}>
                    {courseDetail?.offlineCentres?.map((elem, index) => (
                      <div className={styles.each_radio} key={index}>
                        <input type="radio" id={elem?.offline_centre_id} name={elem?.offline_centre_name} defaultValue={elem?.offline_centre_name} checked={modeData?.location?.toString() === elem?.offline_centre_id?.toString()} onChange={(e) => changeModeData(e.target.id, 'location')} />
                        <label className={modeData?.location === elem?.offline_centre_id?.toString() ? styles.selected_label : ''} for={elem?.offline_centre_id}>{elem?.offline_centre_name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {offlineErrorFlag() &&
              <p className={styles.error_location}>
                <ErrorIcon />
                <span>Please select centre to buy</span>
              </p>}
          </div>}

        {(courseDescription?.length > 0 || courseDescriptionHtml?.length > 0) && (
          <section ref={courseDetailSection} className={styles.courseDetailSection}>
            <h3 className={styles.sectionTitle}>Course Content</h3>

            <div className={styles.content}>
              {courseDescription?.length > 0 && (
                <>
                  {String(courseDescription).slice(0, haveExpandedDescription ? courseDescription.length : 250)}

                  {courseDescription && courseDescription.length > 250 && (
                    <button
                      type="button"
                      className={styles.expandBtn}
                      onClick={() => setHaveExpandedDescription((prev) => !prev)}
                    >
                      Show {haveExpandedDescription ? 'Less' : 'More'}
                    </button>
                  )}
                </>
              )}

              {courseDescriptionHtml?.length > 0 && <div dangerouslySetInnerHTML={{ __html: courseDescriptionHtml }} />}
            </div>
          </section>
        )}

        <div
          className={classNames(styles.aboutAndExamsSectionWrapper, {
            [styles.isRowView]: courseAuthors.length === 1 && examsCovered.length > 0,
          })}
        >
          {courseAuthors.length > 0 && (
            <section ref={authorsSection} className={styles.aboutAuthorsSection}>
              <h3 className={styles.sectionTitle}>About Authors</h3>

              <Authors items={courseAuthors} />
            </section>
          )}

          {examsCovered.length > 0 && (
            <section ref={examsSection} className={styles.examsCoveredSection}>
              <h3 className={styles.sectionTitle}>Exams Covered</h3>

              <ExamsCovered items={examsCovered} slideCount={courseAuthors.length === 1 ? 4 : 6} />
            </section>
          )}
        </div>

        {similarCourses.length > 0 && (
          <section className={classNames(styles.similarCoursesSection)}>
            <SimilarCourses
              courseDetail={courseDetail}
              from="cdp"
              title="Similar Courses"
              tracker={trackerCrossSell}
            />
          </section>
        )}

        <section ref={demoVideosSection} className={styles.demoVideosSection}>
          <DemoVideos
            courseId={courseId}
            defaultLanguage={courseLanguage}
            videos={demoVideos}
          />
        </section>

        {bundleCourses.length > 0 && (
          <section className={styles.bundleCoursesWrapper}>
            <h3 className={styles.sectionTitle}>Get Free With This Course</h3>

            <div className={styles.bundleCourses}>
              {bundleCourses.map((bundleCourse) => (
                <Link
                  key={bundleCourse.courseId || bundleCourse.courseSlug}
                  href={`/course-detail/${bundleCourse.courseId || bundleCourse.courseSlug}`}
                  className={styles.bundleCourse}
                >
                  <div className={styles.bundleCourseImage}>
                    <Image
                      src={bundleCourse.thumbnail}
                      alt={bundleCourse.courseName}
                      objectFit="contain"
                      placeholder="blur"
                      blurDataURL={bundleCourse.thumbnail}
                      fill
                    />
                  </div>

                  <p className={styles.bundleCourseValidity}>
                    Validity:{' '}
                    {bundleCourse.bundleCourseValidity === 0
                      ? 'As per this course'
                      : pluralVal(bundleCourse.bundleCourseValidity, 'day', 'days')}.
                  </p>
                </Link>
              ))}
            </div>
          </section>
        )}

        <section ref={courseContentSection} className={styles.courseContentSection}>
          <CourseContent
            courseId={courseId}
            languageId={courseLanguage}
            isOfflineMode={modeData?.mode === 'offline'}
            offlineCenterId={modeData?.location}
          />
        </section>

        {courseTypeId !== 5 && !courseDetail?.userDetails?.purchased && (
          <section ref={priceSection} className={styles.ourPackagesSection}>
            <h3 className={styles.sectionTitle}>Our Packages</h3>

            <CoursePackages
              courseId={courseId}
              items={coursePackages}
              features={courseFeatures}
            />
          </section>
        )}

        {courseFaqs.length > 0 && (
          <section ref={faqSection} className={styles.courseFaqSection}>
            <h3 className={styles.sectionTitle}>Frequently Asked Questions</h3>

            <FAQsSection items={courseFaqs} />
          </section>
        )}
      </main>

      {emiModal && courseDetail?.courseDetail?.course_id && (
        <CourseEMIModal
          course={courseDetail.courseDetail}
          from={'cdp'}
        />
      )}
      {paymentModal && courseDetail?.courseDetail?.course_id && (
        <PaymentModal
          paymentMethods={activePaymentMethods}
          setPaymentGateway={setPaymentGateway}
          setIsPaymentInitialized={() => dispatch(showEmiModal({ show: false }))}
        />
      )}
    </>
  );
}

export const getServerSideProps = wrapper.getServerSideProps((store) => async ({ params: { slug }, query: { retryOrderId }, ...ctx }) => {
  const { userID, authToken } = getAuthState(ctx);

  const { isAuthenticated } = store.getState().auth;

  if (!isAuthenticated) {
    await store.dispatch(
      validateAppAuth({
        userID,
        apiToken: authToken,
        forceLogin: false,
      }),
    );
  }

  let retryOrder = null;
  try {
    const response = await phpApi.get(`courseview/${slug}`);
    const { success, data: courseDetail } = response.data;

    if (success && userID && authToken) {
      const formData = new FormData();
      formData.append('user_id', userID);
      formData.append('api_token', authToken);
      formData.append('course_slug', slug);

      const userCourseDetailResponse = await phpApi.post('user_course_details', formData);
      if (userCourseDetailResponse?.data?.success === 1) {
        courseDetail.userDetails = objToCamelCase(userCourseDetailResponse?.data?.data);
      }
    } else {
      courseDetail.userDetails = { purchased: false };
    }

    courseDetail.slug = slug;

    if (retryOrderId) {
      const response = await javaApi.get(
        'store-ws/api/v1/course/get/order/history',
        {
          params: { orderId: retryOrderId },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const { success, data } = response.data;
      if (success) {
        retryOrder = objToCamelCase(data);
      }
    }

    return {
      props: {
        courseDetailServer: objToCamelCase(courseDetail),
        retryOrder,
      },
    };
  } catch (e) {
    return { notFound: true };
  }
});

export default CourseDetailPage;
