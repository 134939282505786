/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import AttemptsPopup from '../../../components/AnswerWriting/AttemptsPopup/AttemptsPopup';
import MobileHeader from '../../../components/AnswerWriting/MobileHeader/MobileHeader';
import RippleButton from '../../../components/HomePage/HeroSection/RippleButton';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import { denyReattemptEvl, fetchAssessmentList, getAllOptions, getModelAnswer, showRatingModal, viewSubmission } from '../../../redux/slices/answerWritingSlice';

import DownloadIcon from '../../../assets/images/DownloadIcon.svg';
import EvaluatedSheetIcon from '../../../assets/images/EvaluatedSheets.svg';
import FeedbackIcon from '../../../assets/images/FeedbackIcon.svg';
import ModelAnswerIcon from '../../../assets/images/ModelAnswer.svg';
import MySubmissionIcon from '../../../assets/images/MySubmission.svg';
import RightArrowIcon from '../../../assets/images/RightArrow.svg';
import InfoBtn from '../../../assets/images/answer-writing/info_btn.svg';

import { getModifiedQuestion } from '../../../utils/AnswerWritingUtil';
import PDFViewer from '../../../utils/PDFViewer';

import styles from '../../../styles/AnswerWriting.module.scss';
import RatingModal from '../../../components/AnswerWriting/Rating/RatingModal';

function MySubmissions() {
  const trackMoengageEvent = useMoengageEventTracker();
  const [show, setShow] = React.useState(true);
  const [showRefDoc, setShowRefDoc] = React.useState(false);
  const sourceScreen = useSourceScreen();
  const [showEvaluation, setShowEvaluation] = React.useState(false);
  const [activeDocumentView, setActiveDocumentView] = useState(null);
  const [activeMenu, setActiveMenu] = useState(0);
  const [fetchDataFlag, setFetchDataFlag] = useState(false);
  const [activeMenuListSubmission, setActiveMenuListSubmission] = useState(0);
  const [activeMenuListEvaluate, setActiveMenuListEvaluate] = useState(0);
  const [activeAttempt, setActiveAttempt] = useState(null);
  const [attemptsPopupVisible, setAttemptsPopupVisible] = useState(false);
  const [optionsListArray, setOptionsListArray] = useState([]);
  const [attemptState, setAttemptState] = useState(1);
  const router = useRouter();
  const dispatch = useDispatch();
  const [courseId, languageID] = (router.query.course || '').split('-');

  const {
    viewSubmissionEvaluation,
    viewSubmissionEvaluationLoader,
    lessonResourcesDetails,
    lessonResourcesDetailsLoading,
    modelAnswerDetails,
    ratingModal,
  } = useSelector((state) => ({
    viewSubmissionEvaluation: state?.answerWriting?.viewSubmission?.data,
    viewSubmissionEvaluationLoader: state?.answerWriting?.viewSubmission?.loading,
    lessonResourcesDetails: state?.answerWriting?.assessmentList,
    lessonResourcesDetailsLoading: state?.answerWriting?.assessmentList?.loading,
    modelAnswerDetails: state?.answerWriting?.modelAnswer,
    ratingModal: state?.answerWriting?.ratingModal,
  }));
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const filterAssessment = lessonResourcesDetails?.data?.find(
    (item) => item.assessmentId === router.query.assessmentid,
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !sessionStorage.getItem('ansWrtgCallBackUrl')) router.push('/');
  }, []);

  const getEvlFeedbackFlag = () => {
    let showFlag = 'NO_EVL_FEEDBACK';
    if (activeMenu === 1 &&
      activeMenuListEvaluate === 0 &&
      ((filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' && filterAssessment?.evaluationStatus !== 'RE_ATTEMPT') ||
        filterAssessment?.assessmentSubmissionStatus === 'RE_ATTEMPT_DENIED')
      && viewSubmissionEvaluation?.[0]?.ratingScale === null
    ) {
      showFlag = 'FIRST_ATTEMPT';
    } else if (activeMenu === 1 &&
      activeMenuListEvaluate === 1
      && (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED' ||
        filterAssessment?.assessmentSubmissionStatus === 'RE_ATTEMPT_DENIED')
      && viewSubmissionEvaluation?.[1]?.ratingScale === null
    ) {
      showFlag = 'RE_ATTEMPT';
    }
    return showFlag;
  };
  useEffect(() => {
    const showFeedbackFlag = optionsListArray?.filter((elem) => elem?.reviewedByUser === true);
    if (showFeedbackFlag?.length === 0 && getEvlFeedbackFlag() !== 'NO_EVL_FEEDBACK') {
      dispatch(showRatingModal({ data: null, show: true }));
    } else {
      dispatch(showRatingModal({ data: null, show: false }));
    }
  }, [activeMenu, activeMenuListEvaluate]);

  const fetchAllOptions = async () => {
    const fetchAllOptionsData = await dispatch(
      getAllOptions({ contentId: router.query.assessmentid, businessName: 'StudyIQAssessmentService' }),
    );
    if (fetchAllOptionsData?.payload?.status === 200) {
      const optionList = fetchAllOptionsData?.payload?.response?.OptionList;
      const optionListSliced = optionList?.slice(0, 5) || [];
      setOptionsListArray(optionListSliced);
      const showFeedbackFlag = optionListSliced?.filter((elem) => elem?.reviewedByUser === true);
      if (showFeedbackFlag?.length === 0 && getEvlFeedbackFlag() !== 'NO_EVL_FEEDBACK') {
        dispatch(showRatingModal({ data: null, show: true }));
      } else {
        dispatch(showRatingModal({ data: null, show: false }));
      }
    }
  };

  React.useEffect(() => {
    if (viewSubmissionEvaluation?.length > 0) {
      fetchAllOptions();
    }
  }, [viewSubmissionEvaluation?.length]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    const onBackButtonEvent = () => {
      window.location.href = sessionStorage.getItem('ansWrtgCallBackUrl');
    };
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [router]);

  const handleClickMenu = (type) => {
    if (type === 0) {
      setActiveMenu(0);
      if (viewSubmissionEvaluation?.length && activeMenu !== type) {
        setActiveDocumentView(viewSubmissionEvaluation[0].answerPdfUrl);
        setActiveMenuListSubmission(0);
      }
      setShow(!show);
      setShowEvaluation(false);
    }
    if (type === 1) {
      setActiveMenu(1);
      if (
        viewSubmissionEvaluation &&
        (viewSubmissionEvaluation[0]?.submissionStatus === 'EVALUATED' || viewSubmissionEvaluation[0]?.submissionStatus === 'RE_ATTEMPT_DENIED') &&
        activeMenu !== type
      ) {
        setActiveDocumentView(viewSubmissionEvaluation[0].reportPdfUrl);
        setActiveMenuListEvaluate(0);
        setActiveAttempt(viewSubmissionEvaluation[0]);
      }
      setShow(false);
      setShowEvaluation(!showEvaluation);
    }
    if (type === 2) {
      setActiveDocumentView(modelAnswerDetails?.data?.sampleAnswerLink);
      setActiveMenu(2);
      setShow(false);
      setShowEvaluation(false);
    }
    if (type === 3) {
      setActiveDocumentView(viewSubmissionEvaluation[0]?.allSharedUrlList[0] || viewSubmissionEvaluation[1]?.allSharedUrlList[0]);
      setActiveMenu(3);
      setShowRefDoc(!showRefDoc);
      setShow(false);
      setShowEvaluation(false);
    } else {
      setShowRefDoc(false);
    }
  };

  useEffect(() => {
    (async () => {
      const assesmentListData = await dispatch(
        fetchAssessmentList({
          courseId,
          languageId: parseInt(languageID, 10),
          lessonId: router.query.lessonid,
        }),
      ).unwrap();
      const filterAssessmentData = assesmentListData.data.find(
        (item) => item.assessmentId === router.query.assessmentid,
      );
      const submissionIdWithQuestionIdList = [];

      submissionIdWithQuestionIdList.push({
        submissionId: filterAssessmentData?.submissionId,
        questionId: filterAssessmentData?.allQuestions[0]?.questionId,
      });
      if (
        filterAssessmentData?.reAttemptResponse &&
        (filterAssessmentData?.reAttemptResponse?.assessmentSubmissionStatus === 'SUBMITTED' ||
          filterAssessmentData?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED')
      ) {
        submissionIdWithQuestionIdList.push({
          submissionId: filterAssessmentData?.reAttemptResponse?.submissionId,
          questionId: filterAssessmentData?.reAttemptResponse?.allQuestion[0].questionId,
        });
      }
      const submissionDetails = await dispatch(
        viewSubmission({
          languageId: filterAssessmentData?.assessmentLanguage,
          submissionIdWithQuestionIdList,
        }),
      ).unwrap();
      if (submissionDetails?.length) {
        setActiveDocumentView(submissionDetails[0].answerPdfUrl);
      }
      dispatch(
        getModelAnswer({
          languageId: filterAssessmentData?.assessmentLanguage,
          questionId: filterAssessmentData?.allQuestions?.length && filterAssessmentData?.allQuestions?.[0].questionId,
        }),
      );
    })();
  }, [dispatch, isAuthenticated, fetchDataFlag]);

  const showFeedbackPopup = () => {
    dispatch(showRatingModal({ data: null, show: true }));
  };

  const showSubmissionAttemptsPopup = () => {
    setAttemptsPopupVisible(true);
    setAttemptState(1);
  };

  const showEvaluatedAttemptsPopup = () => {
    setAttemptsPopupVisible(true);
    setAttemptState(2);
  };

  const handleredirectReAttempt = () => {
    let moeObj = sessionStorage.getItem('answritingMoe');
    moeObj = JSON.parse(moeObj);
    moeObj = {
      course_category: moeObj?.courseTypeName,
      course_status: moeObj?.coursePaymentStatus,
      course_type: moeObj?.isCourseFree ? 'paid' : 'free',
      course_id: moeObj?.course_id,
      source_screen: sourceScreen,
      re_attempt_count: filterAssessment?.attemptNumber || '',
    };
    trackMoengageEvent('re-attempt_clicked', moeObj);
    router.push(router.asPath.replace('submitted', 'instructions'));
  };

  const downloadPDF = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'your-file-name.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClickMenuListSubmission = (value, from) => {
    if (value === 0 && from !== 'refDoc') {
      setActiveMenuListSubmission(0);
      setActiveDocumentView(viewSubmissionEvaluation[0]?.answerPdfUrl);
    }
    if (value === 1 && from !== 'refDoc') {
      setActiveMenuListSubmission(1);
      setActiveDocumentView(viewSubmissionEvaluation[1]?.answerPdfUrl);
    }

    if (value === 0 && from === 'refDoc') {
      setActiveMenuListSubmission(0);
      setActiveDocumentView(viewSubmissionEvaluation[0]?.allSharedUrlList[0]);
    }
    if (value === 1 && from === 'refDoc') {
      setActiveMenuListSubmission(1);
      setActiveDocumentView(viewSubmissionEvaluation[1]?.allSharedUrlList[0]);
    }
  };

  const handleClickMenuListEvaluate = (value) => {
    if (value === 0) {
      setActiveMenuListEvaluate(0);
      setActiveDocumentView(viewSubmissionEvaluation[0].reportPdfUrl);
      setActiveAttempt(viewSubmissionEvaluation[0]);
    }
    if (value === 1) {
      setActiveMenuListEvaluate(1);
      setActiveAttempt(viewSubmissionEvaluation[1]);
      setActiveDocumentView(viewSubmissionEvaluation[1].reportPdfUrl);
    }
  };

  const denyReattempt = async () => {
    const denyReattemptData = await dispatch(
      denyReattemptEvl({
        denied: true,
        denyReason: 'default',
        languageId: filterAssessment?.assessmentLanguage,
        questionId: filterAssessment?.allQuestions?.length && filterAssessment?.allQuestions?.[0].questionId,
        submissionId: filterAssessment?.submissionId,
      }),
    );
    if (denyReattemptData?.payload?.status === 200) {
      setFetchDataFlag(!fetchDataFlag);
    }
  };

  const getModelAnswerFlag = () => {
    let showFlag = true;
    if (filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' || filterAssessment?.assessmentSubmissionStatus === 'RE_ATTEMPT_DENIED') {
      showFlag = true;
      if (filterAssessment?.evaluationStatus === 'RE_ATTEMPT' && filterAssessment?.assessmentSubmissionStatus === 'EVALUATED') {
        showFlag = false;
      }
    } else {
      showFlag = false;
    }
    if (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED') {
      showFlag = true;
    }
    return showFlag;
  };

  return (
    <div className={styles.container}>
      <div>
        <MobileHeader HeadText={filterAssessment && filterAssessment?.clientRef3} />
      </div>
      <div className={styles.statusWrapper}>
        <div className={styles.statusInfo}>
          <div className={styles.status}>
            Status:
            {viewSubmissionEvaluation?.length && (viewSubmissionEvaluation?.[0]?.submissionStatus === 'EVALUATED' || viewSubmissionEvaluation[0]?.submissionStatus === 'RE_ATTEMPT_DENIED') ? (
              <span className={styles.evaluated}>Evaluated</span>
            ) : (
              <span className={styles.submitted}>Submitted</span>
            )}
          </div>
          <div className={styles.status}>
            Submitted On:
            <span
              className={classNames({
                [styles.textSkelton]: viewSubmissionEvaluationLoader,
              })}
            >
              {viewSubmissionEvaluation?.length &&
                viewSubmissionEvaluation[0]?.submittedOn &&
                moment.unix(viewSubmissionEvaluation[0].submittedOn).format('DD-MM-YYYY')}
            </span>
          </div>
        </div>
        <div className={styles.reviewInfo}>
          {viewSubmissionEvaluation?.length && (viewSubmissionEvaluation[0]?.submissionStatus === 'EVALUATED' || viewSubmissionEvaluation[0]?.submissionStatus === 'RE_ATTEMPT_DENIED') && (
            <>
              <div className={styles.review}>
                Reviewed On:
                <span
                  className={classNames({
                    [styles.textSkelton]: viewSubmissionEvaluationLoader,
                  })}
                >
                  {viewSubmissionEvaluation?.length &&
                    viewSubmissionEvaluation[0].evaluatedOn &&
                    moment.unix(viewSubmissionEvaluation[0].evaluatedOn).format('DD-MM-YYYY')}
                </span>
              </div>
              <div className={styles.review}>
                Score:
                <span
                  className={classNames({
                    [styles.textSkelton]: viewSubmissionEvaluationLoader,
                  })}
                >
                  {viewSubmissionEvaluation[0].marksObtained}
                </span>
                /{viewSubmissionEvaluation[0].questionMaximumMarks}
              </div>
            </>
          )}
        </div>
      </div>
      {filterAssessment?.reAttemptResponse &&
        (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED' ||
          filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'SUBMITTED') && (
          <div className={styles.statusWrapper}>
            <div className={styles.statusInfo}>
              <div className={styles.status}>
                Status:
                {viewSubmissionEvaluation?.length > 1 &&
                  ((filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED' ||
                    filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'SUBMITTED') &&
                    filterAssessment?.reAttemptResponse?.evaluatedMarks !== null) ? (
                      <span className={styles.evaluated}>Reattempt {filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'SUBMITTED' ? 'Submitted' : 'Evaluated'}</span>
                ) : (
                  <span className={styles.submitted}>Reattempt Sent</span>
                )}
              </div>
              <div className={styles.status}>
                Submitted On:
                <span
                  className={classNames({
                    [styles.textSkelton]: viewSubmissionEvaluationLoader,
                  })}
                >
                  {viewSubmissionEvaluation?.length > 1 &&
                    viewSubmissionEvaluation[1]?.submittedOn &&
                    moment.unix(viewSubmissionEvaluation[1].submittedOn).format('DD-MM-YYYY')}
                </span>
              </div>
            </div>
            <div className={styles.reviewInfo}>
              {viewSubmissionEvaluation?.length && (viewSubmissionEvaluation[1]?.submissionStatus === 'EVALUATED' || viewSubmissionEvaluation[0]?.submissionStatus === 'RE_ATTEMPT_DENIED') && (
                <>
                  <div className={styles.review}>
                    Reviewed On:
                    <span>
                      {viewSubmissionEvaluation?.length > 1 &&
                        viewSubmissionEvaluation[1].evaluatedOn &&
                        moment.unix(viewSubmissionEvaluation[1].evaluatedOn).format('DD-MM-YYYY')}
                    </span>
                  </div>
                  <div className={styles.review}>
                    Score:
                    <span>{viewSubmissionEvaluation[1].marksObtained}</span>/
                    {viewSubmissionEvaluation[1].questionMaximumMarks}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      <div
        className={classNames(styles.questionWrapper, {
          [styles.questionWrapperSubmitScrn]: true,
        })}
      >
        <div
          className={classNames(styles.question, {
            [styles.textSkelton]: lessonResourcesDetailsLoading,
          })}
        >
          {(filterAssessment?.allQuestions[0]?.questionHtmlTitle || filterAssessment?.allQuestions[0]?.question) && (
            <div
              dangerouslySetInnerHTML={{
                __html: getModifiedQuestion(
                  filterAssessment?.allQuestions[0]?.questionHtmlTitle,
                  filterAssessment?.allQuestions[0]?.question,
                ),
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.submitionWrapper}>
        <div className={styles.leftMenu}>
          <div
            className={
              activeMenu === 0
                ? classNames(styles.menuActive, {
                  [styles.submissionToggle]: true,
                })
                : classNames(styles.menu, { [styles.submissionToggle]: true })
            }
            onClick={() => handleClickMenu(0)}
          >
            My Submission
            {viewSubmissionEvaluation?.length > 1 && (
              <span>
                <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} className={styles.expandIcon} />
              </span>
            )}
          </div>
          {show &&
            viewSubmissionEvaluation?.length > 1 &&
            (viewSubmissionEvaluation[0]?.submissionStatus === 'EVALUATED' || viewSubmissionEvaluation[0]?.submissionStatus === 'RE_ATTEMPT_DENIED') && (
              <div className={styles.submissionList}>
                <ul>
                  <li
                    className={activeMenuListSubmission === 0 ? styles.activeMenuList : styles.menuList}
                    onClick={() => handleClickMenuListSubmission(0)}
                    data-testid="first-attempt"
                  >
                    First Attempt
                  </li>
                  <li
                    className={activeMenuListSubmission === 1 ? styles.activeMenuList : styles.menuList}
                    onClick={() => handleClickMenuListSubmission(1)}
                    data-testid="re-attempt"
                  >
                    Reattempt
                  </li>
                </ul>
              </div>
            )}
          <div
            className={
              activeMenu === 3
                ? classNames(styles.menuActive, {
                  [styles.submissionToggle]: true,
                  [styles.disabled_noneDisplay]: !(viewSubmissionEvaluation?.[0]?.allSharedUrlList?.length || viewSubmissionEvaluation?.[1]?.allSharedUrlList?.length),
                })
                : classNames(styles.menu, {
                  [styles.submissionToggle]: true,
                  [styles.disabled_noneDisplay]: !(viewSubmissionEvaluation?.[0]?.allSharedUrlList?.length || viewSubmissionEvaluation?.[1]?.allSharedUrlList?.length),
                })
            }
            onClick={() => handleClickMenu(3)}
            data-testid="reference-document"
          >
            Reference Document
            {(viewSubmissionEvaluation?.length > 1
              && viewSubmissionEvaluation?.[1]?.allSharedUrlList?.length > 0
              && viewSubmissionEvaluation?.[0]?.allSharedUrlList?.length > 0)
              && (
                <span>
                  <FontAwesomeIcon icon={showRefDoc ? faChevronUp : faChevronDown} className={styles.expandIcon} />
                </span>
              )}
          </div>
          {showRefDoc && (viewSubmissionEvaluation?.length > 1 &&
            (viewSubmissionEvaluation?.[0]?.allSharedUrlList?.length > 0 &&
              viewSubmissionEvaluation?.[1]?.allSharedUrlList?.length > 0)) ? (
                <div className={styles.submissionList}>
                  <ul>
                    {viewSubmissionEvaluation[0]?.allSharedUrlList?.length > 0 &&
                    <li
                      className={activeMenuListSubmission === 0 ? styles.activeMenuList : styles.menuList}
                      onClick={() => handleClickMenuListSubmission(0, 'refDoc')}
                      data-testid="first-attempt-reference"
                    >
                      First Attempt
                    </li>}

                    {viewSubmissionEvaluation[1]?.allSharedUrlList?.length > 0 &&
                    <li
                      className={activeMenuListSubmission === 1 ? styles.activeMenuList : styles.menuList}
                      onClick={() => handleClickMenuListSubmission(1, 'refDoc')}
                      data-testid="re-attempt-reference"
                    >
                      Reattempt
                    </li>}
                  </ul>
                </div>
          ) : ''}
          <div
            className={classNames({
              [styles.disabled]: !(
                viewSubmissionEvaluation?.length && (viewSubmissionEvaluation?.[0]?.submissionStatus === 'EVALUATED' || viewSubmissionEvaluation?.[0]?.submissionStatus === 'RE_ATTEMPT_DENIED')
              ),
            })}
          >
            <div
              onClick={() => handleClickMenu(1)}
              data-testid="evaluated-sheet"
              className={
                activeMenu === 1
                  ? classNames(styles.menuActive, {
                    [styles.submissionToggle]: true,
                  })
                  : classNames(styles.menu, { [styles.submissionToggle]: true })
              }
            >
              Evaluated Sheet
              {viewSubmissionEvaluation?.length > 1 &&
                filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED' && (
                  <span>
                    <FontAwesomeIcon
                      icon={showEvaluation ? faChevronUp : faChevronDown}
                      className={styles.expandIcon}
                    />
                  </span>
                )}
            </div>
            {showEvaluation &&
              viewSubmissionEvaluation?.length > 1 &&
              (viewSubmissionEvaluation[1].submissionStatus === 'EVALUATED' || viewSubmissionEvaluation[0]?.submissionStatus === 'RE_ATTEMPT_DENIED') && (
                <div className={styles.submissionList}>
                  <ul>
                    <li
                      className={activeMenuListEvaluate === 0 ? styles.activeMenuList : styles.menuList}
                      onClick={() => handleClickMenuListEvaluate(0)}
                      data-testid="first-attempt-evaluated"
                    >
                      First Attempt
                    </li>
                    <li
                      className={activeMenuListEvaluate === 1 ? styles.activeMenuList : styles.menuList}
                      onClick={() => handleClickMenuListEvaluate(1)}
                      data-testid="re-attempt-evaluated"
                    >
                      Reattempt
                    </li>
                  </ul>
                </div>
              )}
          </div>
          {getModelAnswerFlag() &&
            modelAnswerDetails?.data?.sampleAnswerLink && (
              <div
                className={!modelAnswerDetails?.data?.sampleAnswerLink && styles.disabled}
                onClick={() => handleClickMenu(2)}
                data-testid="model-answer"
              >
                <div className={activeMenu === 2 ? styles.menuActive : styles.menu}>Model Answer</div>
              </div>
            )}
        </div>
        <div className={styles.rightSection}>
          <div
            className={classNames(styles.pdfViewer, {
              [styles.pdfViewerEmpty]: !activeDocumentView,
            })}
          >
            <div className={styles.demoText}>
              <PDFViewer activeDocumentView={activeDocumentView} className={styles.pdfContainer} />
            </div>
            <div className={styles.downloadIcon}>
              {activeDocumentView && (
                <a href={activeDocumentView} download target="_blank" rel="noreferrer">
                  <DownloadIcon />
                </a>
              )}
            </div>
          </div>
          {activeMenu === 1 && (
            <div className={styles.commentRemarksWrapper}>
              <div className={styles.comentsRemarksTxt}>
                <span>Evaluator Comments: </span>
                {activeAttempt?.evaluatorComment}
              </div>
              <div className={styles.comentsRemarksTxt}>
                <span>Evaluator Remarks: </span>
                {activeAttempt?.evaluatorRemark}
              </div>
            </div>
          )}
        </div>
      </div>
      {filterAssessment &&
        filterAssessment?.evaluationStatus === 'RE_ATTEMPT' &&
        filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' &&
        (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'ATTEMPTED' ||
          !filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus) && (
          <div className={styles.feedbackAdvise}>
            <span className={styles.text_main}>
              <span className={styles.image_main}>
                <InfoBtn />
              </span>
              <span className={styles.text}>The evaluator advised you to re-attempt this question</span>
            </span>
          </div>)}
      <div className={styles.feedbackMenu}>
        <div className={styles.feedback_evl_wrapper} />

        {filterAssessment &&
          filterAssessment?.evaluationStatus === 'RE_ATTEMPT' &&
          filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' &&
          (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'ATTEMPTED' ||
            !filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus) && (
            <div className={styles.reattemptBtn_wrapper}>
              <div className={styles.denyBtn}>
                <RippleButton
                  className={styles.denybtn}
                  onClick={() => denyReattempt()}
                  rippleColor="#fff"
                  opacity={0.38}
                >
                  Deny
                </RippleButton>
              </div>
              <div className={styles.reattemptBtn}>
                <RippleButton
                  className={styles.btn}
                  type="submit"
                  onClick={handleredirectReAttempt}
                  rippleColor="#fff"
                  opacity={0.38}
                >
                  Re-Attempt
                </RippleButton>
              </div>
            </div>
          )}
      </div>
      {/* // Mobile Submission Menu */}
      <div className={styles.submitionMenuMobile}>
        <div className={styles.menuMobile} onClick={showSubmissionAttemptsPopup} data-testid="my-submission">
          <div className={styles.leftMenuMobile}>
            <div className={styles.icon}>
              <MySubmissionIcon />
            </div>
            <div className={styles.name}>My Submission</div>
          </div>
          <div className={styles.rightArrow}>
            <RightArrowIcon />
          </div>
        </div>

        {viewSubmissionEvaluation?.length && (viewSubmissionEvaluation[0]?.submissionStatus === 'EVALUATED' || viewSubmissionEvaluation[0]?.submissionStatus === 'RE_ATTEMPT_DENIED') && (
          <div className={styles.menuMobile} onClick={showEvaluatedAttemptsPopup} data-testid="evaluated-sheet">
            <div className={styles.leftMenuMobile}>
              <div className={styles.icon}>
                <EvaluatedSheetIcon />
              </div>
              <div className={styles.name}>Evaluated Sheet</div>
            </div>
            <div className={styles.rightArrow}>
              <RightArrowIcon />
            </div>
          </div>
        )}
        {getModelAnswerFlag() &&
          modelAnswerDetails?.data?.sampleAnswerLink && (
            <div className={styles.menuMobile} onClick={() => downloadPDF(modelAnswerDetails?.data?.sampleAnswerLink)} data-testid="model-answer">
              <div className={styles.leftMenuMobile}>
                <div className={styles.icon}>
                  <ModelAnswerIcon />
                </div>
                <div className={styles.name}>Model Answer</div>
              </div>
              <div className={styles.rightArrow}>
                <RightArrowIcon />
              </div>
            </div>
          )}
        <div className={styles.commentWrapperMobile}>
          {viewSubmissionEvaluation?.length &&
            (viewSubmissionEvaluation[0]?.evaluatorComment || viewSubmissionEvaluation[0]?.evaluatorRemark) && (
              <div>
                <div className={styles.headingComment}>First Attempt:</div>
                <div className={styles.commentRemarksWrapper}>
                  <div className={styles.comentsRemarksTxt}>
                    <span>Evaluator Comments: </span>
                    {viewSubmissionEvaluation[0]?.evaluatorComment}
                  </div>
                  <div className={styles.comentsRemarksTxt}>
                    <span>Evaluator Remarks: </span>
                    {viewSubmissionEvaluation[0]?.evaluatorRemark}
                  </div>
                </div>
              </div>
            )}
          {viewSubmissionEvaluation.length > 1 &&
            (viewSubmissionEvaluation[1]?.evaluatorComment || viewSubmissionEvaluation[1]?.evaluatorRemark) && (
              <div>
                <div className={styles.headingComment}>Reattempt:</div>
                <div className={styles.commentRemarksWrapper}>
                  <div className={styles.comentsRemarksTxt}>
                    <span>Evaluator Comments: </span>
                    {viewSubmissionEvaluation[1]?.evaluatorComment}
                  </div>
                  <div className={styles.comentsRemarksTxt}>
                    <span>Evaluator Remarks: </span>
                    {viewSubmissionEvaluation[1]?.evaluatorRemark}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <div>
        <AttemptsPopup
          show={attemptsPopupVisible}
          attemptState={attemptState}
          reAttemptEvaluated={
            attemptState === 1
              ? filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' &&
              (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'SUBMITTED' ||
                filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED')
              : filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED'
          }
          hidePopup={() => setAttemptsPopupVisible(false)}
          viewSubmissionEvaluation={viewSubmissionEvaluation}
          downloadPDF={downloadPDF}
          data-testid="attempts-popup"
        />
      </div>
      {/* Mobile Feedback Menu */}

      <div className={styles.feedbackMenuobile_wrapper}>
        {filterAssessment &&
          filterAssessment?.evaluationStatus === 'RE_ATTEMPT' &&
          filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' &&
          (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'ATTEMPTED' ||
            !filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus) && (
            <div className={styles.feedbackAdviseMobile}>
              <span className={styles.text_main}>
                <span className={styles.image_main}>
                  <InfoBtn />
                </span>
                <span className={styles.text}>The evaluator advised you to re-attempt this question</span>
              </span>
            </div>)}
        <div className={styles.feedbackMenuobile}>
          <div className={styles.feedbackSectionMobile} onClick={showFeedbackPopup} data-testid="feedback">
            {((filterAssessment &&
              filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' &&
              (!filterAssessment?.reAttemptResponse ||
                filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === null) &&
              viewSubmissionEvaluation[0]?.ratingScale === null) ||
              (filterAssessment &&
                filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'EVALUATED' &&
                viewSubmissionEvaluation?.length &&
                viewSubmissionEvaluation[1]?.ratingScale === null)) && (
                // eslint-disable-next-line react/jsx-indent
                <>
                  <div className={styles.feedbackIconMobile}>
                    <FeedbackIcon />
                  </div>
                  <div className={styles.feedbackTextMobile}>Feedback</div>
                </>
              )}
          </div>
          {filterAssessment &&
            filterAssessment?.assessmentSubmissionStatus === 'EVALUATED' &&
            (filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus === 'ATTEMPTED' ||
              !filterAssessment?.reAttemptResponse?.assessmentSubmissionStatus) && (
              <div className={styles.reattemptBtn_wrapperMobile}>
                <div className={styles.denyBtnMobile}>
                  <RippleButton
                    className={styles.denybtn}
                    onClick={() => denyReattempt()}
                    rippleColor="#fff"
                    opacity={0.38}
                  >
                    Deny
                  </RippleButton>
                </div>
                <div className={styles.reattemptBtnMobile}>
                  <RippleButton className={styles.btn} type="submit" onClick={handleredirectReAttempt}>
                    Re-Attempt
                  </RippleButton>
                </div>
              </div>

            )}
        </div>
      </div>
      {(ratingModal?.show && optionsListArray?.length > 0) && <RatingModal
        optionsListArray={optionsListArray}
        contentId={filterAssessment?.assessmentId}
        contentType={'Assessment'}
        timeOut={5000}
        close={() => { fetchAllOptions(); }}
        data-testid="rating-modal"
      />}
    </div>
  );
}

export default MySubmissions;
